import React from 'react';
import { Link } from "react-router-dom";
import './Footer.css';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section">
          <img src="/images/logo-transparent.png" alt="BioStrive Logo" className="footer-logo" />
          <address>
            7157 Narcoossee Road<br />
            Orlando, FL 32822
          </address>
        </div>
        <div className="footer-section">
          <h3>Check out our socials</h3>
          <ul>
            <li>
              <a href="https://www.linkedin.com/company/biostrive/" target="_blank" rel="noopener noreferrer">
                <img src="/images/icons/icons8-linkedin.svg" alt="LinkedIn" className="social-icon" /> LinkedIn
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/biostrivellc/" target="_blank" rel="noopener noreferrer">
                <img src="/images/icons/icons8-instagram.svg" alt="Instagram" className="social-icon" /> Instagram
              </a>
            </li>
            <li>
              <a href="https://twitter.com/BioStrive" target="_blank" rel="noopener noreferrer">
                <img src="/images/icons/icons8-twitterx.svg" alt="Twitter" className="social-icon" /> Twitter
              </a>
            </li>
          </ul>
        </div>
        <div className="footer-section">
  <h3>Contact us</h3>
  <p>
    <a href="mailto:biostrive@biostrive.org" className="email-link">
      <img src="/images/icons/icons8-letter-30.png" alt="Email Icon" />
      biostrive@biostrive.org
    </a>
  </p>
</div>

        <div className="footer-section newsletter">
          <h3>Newsletter</h3>
          <p>
          <Link to="/newsletter" className="newsletter-link">
      Sign up here for BioStrive’s newsletter to receive the latest information →</Link>
          </p>
        </div>
      </div>
      <div className="footer-bottom">
      <p>© BioStrive 2024. All Rights Reserved.
      </p>
      </div>
    </footer>
  );
}

export default Footer;
