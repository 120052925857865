import React, { useState, useEffect, useRef } from 'react';
import "../css/Home.css";
import Banner from '../components/Banner';
import { NavLink } from 'react-router-dom';


function Home() {
  const [fadeIn, setFadeIn] = useState(false);

  //an attempt to make the down arrow clickable and scrollable
  const targetRef = useRef(null);
  const handleScroll = () => {
    if (targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    // this is brainrot for the fade effect on the arrow under the navbar
    setFadeIn(true);
  }, []);
  return (
    <>
      {/* <div className="contact-banner">
      <h1 className="contact-main-title">Home</h1>
      <p className="contact-subtitle">Let's keep in touch</p>
      </div> */}
      <div>
      <Banner
        logo="/images/logo-transparent.png"
        title="Welcome to Our Site"
        subtitle="Innovation That Transforms Lives"
        backgroundVideo="/images/beach.mp4"
        backgroundImage="/images/contact-banner-placeholder.jpg"
/>
      </div>
      <div className='homepage-container'>
        <img
          src='/images/icons/down-arrow.png'
          className={`homepage-arrow ${fadeIn ? 'fade-in' : ''}`}
          alt='Continue'
          onClick={handleScroll}
        />
        <div className='homepage-paragraph-container'>

          <h1 className='homepage-header' ref={targetRef}>Our Mission</h1>
          <p className='ourmission-p'>BioStrive specializes in propelling growth-stage BioTech, MedTech, and life science companies towards success through commercialization, data analysis, market access, and research. Our team combines industry insights and experience to deliver tailored business solutions that meet the unique needs of our clients. We focus on maximizing market impact, unlocking actionable development opportunities, and advancing scientific research. We are committed to bridging the gap between technological innovation and patient care, shaping the future of healthcare and improving lives.</p>
        </div>
        <div className='homepage-services-container'>
          <h1 className='homepage-header'>Our Services</h1>
          {/* !!! Keep this, there will need to be a separate grid eventually. The one rn is reused. */}
          {/* <div className='services-grid'>
            <div className='services-grid-item'>Item 1</div>
            <div className='services-grid-item'>Item 2</div>
            <div className='services-grid-item'>Item 3</div>
            <div className='services-grid-item'>Item 4</div>
          </div> */}
          <div className="services-grid">
            <NavLink to={"/market-access"} className="no-underline">
              <div className="service-item">
                <img src="/images/Market_Access.jpeg" alt="Market Access & Evidence Generation" className='service-img' />
                <h3 className='homepage-service-item'>Market Access & Evidence Generation &rsaquo;</h3>
              </div>
            </NavLink>
            <NavLink to={"/technology"} className="no-underline">
              <div className="service-item">
                <img src="/images/Technology.jpeg" alt="Technology" className='service-img' />
                <h3 className='homepage-service-item'>Technology &rsaquo;</h3>

              </div>
            </NavLink>
            <NavLink to={"/MarketAndBD"} className="no-underline">
            <div className="service-item">
              <img src="/images/MarketingBusiness.jpeg" alt="Marketing & Business Development" className='service-img' />
              <h3 className='homepage-service-item'>Marketing & Business Development &rsaquo;</h3>
            </div>
            </NavLink>
            <NavLink to={"/talent"} className="no-underline">
            <div className="service-item">
              <img src="/images/Internship.jpeg" alt="Internship & Talent Acquisition" className='service-img' />
              <h3 className='homepage-service-item'>Internship & Talent Acquisition &rsaquo;</h3>
            </div>
            </NavLink>
          </div>
        </div>
        <div className='homepage-publications-container'>
          <h1 className="homepage-header">Recent Publications</h1>
          <div
            className='homepage-publications-box ourmission-p'
            onClick={() =>
              window.open(
                'https://www.linkedin.com/company/biostrive/posts/?feedView=articles',
                '_blank'
              )
            }
          >
            <p>Click here to read our publications on our LinkedIn page</p>
          </div>

        </div>
      </div>
    </>
  );

}

export default Home;
