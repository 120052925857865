import React from 'react'
import Banner from '../components/Banner'
import '../css/Technology.css'
import { NavLink } from 'react-router-dom'
import { FaLinkedin } from "react-icons/fa";
import { useEffect } from 'react';



export default function MarketAndBD() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (<>
        <Banner
            title="Marketing And Business Development"
            backgroundImage="/images/MarketingBusiness.jpeg"
            className="tech-banner"
        />

        <div className='technology-con'>


            <div className='tech-text-con'>
                <h1 className='tech-h1'>Brand and Business Management</h1>

                <p className='tech-info'>Marketing, finance, and business development are key to growth. In a competitive market, good marketing creates awareness and sets the company apart.
                    Business development builds relationships and finds new opportunities to boost revenue. Together, we will attract interest and turn it into real results, like partnerships,
                    sales, and long-term customer loyalty.</p>

                <hr class="page-break" />

                <h2 className='tech-h2'>Our Solutions</h2>

                <div className="images-con">
                    <div className="profile-card">
                        <img src="/images/MarketAndBDImages/Brand_Positioning.jpg" alt="Brand Positioning" />
                        <h3 className="h3-title">Brand Positioning, Campaign Strategy & Messaging</h3>
                    </div>
                    <div className="profile-card">
                        <img src="/images/MarketAndBDImages/Competitive_Landscape_Crop.png" alt="Competitive Landscape" />
                        <h3 className="h3-title">Data Management & Analytics</h3>
                    </div>
                    <div className="profile-card">
                        <img src="/images/MarketAndBDImages/Fundraising_Crop.png" alt="Fundraising Support" />
                        <h3 className="h3-title">Fundraising Support</h3>
                    </div>
                    <div className="profile-card">
                        <img src="/images/MarketAndBDImages/Financial_Planning_Crop.png" alt="Financial Planning" />
                        <h3 className="h3-title">Financial Planning & Analysis</h3>
                    </div>




                </div>
                <hr class="page-break" />


                <h2 className='tech-h2'>Our Team</h2>
                <div className="tech-grid">
                    <NavLink target="_blank" className="no-underline" to={"https://www.linkedin.com/in/armin-brumand-aa5756297/"}>
                        <div className="profile-card">
                            <img className="tech-image" src='./images/MarketAndBDImages/headshots/ArminB_Crop.png' alt='armin' />
                            <div className="person-text-con">
                                <h4 className="tech-name">Armin Brumand</h4>
                                <p className="title">Head of Digital Marketing</p>
                                <div className="linked-in-con">
                                    <FaLinkedin />
                                    <p>View LinkedIn</p>
                                </div>
                            </div>
                        </div>
                    </NavLink>

                    <NavLink target="_blank" className="no-underline" to={"https://www.linkedin.com/in/philip-solomon-8a5456222/"}>
                        <div className="profile-card">
                            <img className="tech-image" src='./images/MarketAndBDImages/headshots/PhilipS_Crop.png' alt='philip' />
                            <div className="person-text-con">
                                <h4 className="tech-name">Philip Solomon</h4>
                                <p className="title">Business Development Intern</p>
                                <div className="linked-in-con">
                                    <FaLinkedin />
                                    <p>View LinkedIn</p>
                                </div>
                            </div>
                        </div>
                    </NavLink>

                    <NavLink target="_blank" className="no-underline" to={"https://www.linkedin.com/in/ethan-minvielle-86b09a265/"}>
                        <div className="profile-card">
                            <img className="tech-image" src='./images/MarketAndBDImages/headshots/EthanM_Crop.png' alt='ethan' />
                            <div className="person-text-con">
                                <h4 className="tech-name">Ethan Minvielle</h4>
                                <p className="title">Business Development Intern</p>
                                <div className="linked-in-con">
                                    <FaLinkedin />
                                    <p>View LinkedIn</p>
                                </div>
                            </div>
                        </div>
                    </NavLink>

                    <NavLink target="_blank" className="no-underline" to={"https://www.linkedin.com/in/luka-afonine/"}>
                        <div className="profile-card">
                            <img className="tech-image" src='./images/MarketAndBDImages/headshots/LukaA_Crop.png' alt='luka' />
                            <div className="person-text-con">
                                <h4 className="tech-name">Luka Afonine</h4>
                                <p className="title">Research Assistant</p>
                                <div className="linked-in-con">
                                    <FaLinkedin />
                                    <p>View LinkedIn</p>
                                </div>
                            </div>
                        </div>
                    </NavLink>

                    <NavLink target="_blank" className="no-underline" to={"https://www.linkedin.com/in/kadenconnelley/"}>
                        <div className="profile-card">
                            <img className="tech-image" src='./images/MarketAndBDImages/headshots/KadenC_Crop.png' alt='kaden' />
                            <div className="person-text-con">
                                <h4 className="tech-name">Kaden Connelley</h4>
                                <p className="title">Clinical Intern</p>
                                <div className="linked-in-con">
                                    <FaLinkedin />
                                    <p>View LinkedIn</p>
                                </div>
                            </div>
                        </div>
                    </NavLink>

                    <NavLink target="_blank" className="no-underline" to={"https://www.linkedin.com/in/ananya-pradhan-008115246/"}>
                        <div className="profile-card">
                            <img className="tech-image" src='./images/MarketAndBDImages/headshots/AnanyaP_Crop.png' alt='ananya' />
                            <div className="person-text-con">
                                <h4 className="tech-name">Ananya Pradhan</h4>
                                <p className="title">Marketing Intern</p>
                                <div className="linked-in-con">
                                    <FaLinkedin />
                                    <p>View LinkedIn</p>
                                </div>
                            </div>
                        </div>
                    </NavLink>

                    <NavLink target="_blank" className="no-underline" to={"https://www.linkedin.com/in/ila-gowda/"}>
                        <div className="profile-card">
                            <img className="tech-image" src='./images/MarketAndBDImages/headshots/IlaG_Crop.png' alt='ila' />
                            <div className="person-text-con">
                                <h4 className="tech-name">Ila Gowda</h4>
                                <p className="title">Marketing Intern</p>
                                <div className="linked-in-con">
                                    <FaLinkedin />
                                    <p>View LinkedIn</p>
                                </div>
                            </div>
                        </div>
                    </NavLink>

                    <NavLink target="_blank" className="no-underline" to={"https://www.linkedin.com/in/vichfang/"}>
                        <div className="profile-card">
                            <img className="tech-image" src='./images/MarketAndBDImages/headshots/VictorF_Crop.png' alt='victor' />
                            <div className="person-text-con">
                                <h4 className="tech-name">Victor Fang</h4>
                                <p className="title">UI/UX Intern</p>
                                <div className="linked-in-con">
                                    <FaLinkedin />
                                    <p>View LinkedIn</p>
                                </div>
                            </div>
                        </div>
                    </NavLink>
                </div>






            </div>
        </div>
    </>
    )
}
