import React from 'react'
import Banner from '../components/Banner'
import '../css/Technology.css'
import { NavLink } from 'react-router-dom'
import { FaLinkedin } from "react-icons/fa";
import { useEffect } from 'react';



export default function Technology() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Banner
                title="Technology"
                backgroundImage="/images/Technology.jpeg"
                className="tech-banner"
            />
            <div className='technology-con'>


                <div className='tech-text-con'>
                    <h1 className='tech-h1'>AI, Data Science, and Software Development</h1>

                    <p className='tech-info'>Software engineering and data analytics are important to support product development and decision-making. Good software helps create solutions that meet regulations and the needs of providers and patients. Data analytics helps to understand market trends, customer behavior, and product performance. By using data, we can help you make better decisions, improve your products, and find new growth opportunities.</p>

                    <hr class="page-break" />

                    <h2 className='tech-h2'>Our Solutions</h2>

                    <div className="images-con">
                        <div className="profile-card">
                            <img src="/images/technologyImages/AI.jpg" alt="AI Integration" />
                            <h3 className="h3-title">AI Integration</h3>
                        </div>
                        <div className="profile-card">
                            <img src="/images/technologyImages/Data_Management_Crop.png" alt="Data Management & Analytics" />
                            <h3 className="h3-title">Data Management & Analytics</h3>
                        </div>
                        <div className="profile-card">
                            <img src="/images/technologyImages/CRM_Solutions.jpg" alt="CRM Solutions" />
                            <h3 className="h3-title">CRM Solutions</h3>
                        </div>
                        <div className="profile-card">
                            <img src="/images/technologyImages/Web_Design_Crop.png" alt="Web Development" />
                            <h3 className="h3-title">Web Development</h3>
                        </div>





                    </div>
                    <hr class="page-break" />

                    <h2 className='tech-h2'>Our Team</h2>
                    <div className="tech-grid">
                        <NavLink target="_blank" className="no-underline" to={"https://www.linkedin.com/in/arya-gharib/"}>
                            <div className="profile-card">
                                <img className="tech-image" src='./images/technologyImages/headshots/AryaG_Crop.png' alt='arya' />
                                <div className="person-text-con">
                                    <h4 className="tech-name">Arya Gharib</h4>
                                    <p className="title">Director of Technology</p>
                                    <div className="linked-in-con">
                                        <FaLinkedin />
                                        <p>View LinkedIn</p>
                                    </div>
                                </div>
                            </div>
                        </NavLink>

                        <NavLink target="_blank" className="no-underline" to={"https://www.linkedin.com/in/nikitakastyshyn/"}>
                            <div className="profile-card">
                                <img className="tech-image" src='./images/technologyImages/headshots/Nikita_Crop.jpg' alt='nikita' />
                                <div className="person-text-con">
                                    <h4 className="tech-name">Nikita Kastyshin</h4>
                                    <p className="title">Software Engineer</p>
                                    <div className="linked-in-con">
                                        <FaLinkedin />
                                        <p>View LinkedIn</p>
                                    </div>
                                </div>
                            </div>
                        </NavLink>

                        <NavLink target="_blank" className="no-underline" to={"https://www.linkedin.com/in/harsh-patil-746421268/"}>
                            <div className="profile-card">
                                <img className="tech-image" src='./images/technologyImages/headshots/HarshP_Crop.png' alt='harsh' />
                                <div className="person-text-con">
                                    <h4 className="tech-name">Harsh Patil</h4>
                                    <p className="title">Software Engineer</p>
                                    <div className="linked-in-con">
                                        <FaLinkedin />
                                        <p>View LinkedIn</p>
                                    </div>
                                </div>
                            </div>
                        </NavLink>

                        <NavLink target="_blank" className="no-underline" to={"https://www.linkedin.com/in/zacharydavwood/"}>
                            <div className="profile-card">
                                <img className="tech-image" src='./images/technologyImages/headshots/Zach.JPG' alt='zach' />
                                <div className="person-text-con">
                                    <h4 className="tech-name">Zach Wood</h4>
                                    <p className="title">Software Engineer</p>
                                    <div className="linked-in-con">
                                        <FaLinkedin />
                                        <p>View LinkedIn</p>
                                    </div>
                                </div>
                            </div>
                        </NavLink>

                        <NavLink target="_blank" className="no-underline" to={"https://www.linkedin.com/in/alsellappan/"}>
                            <div className="profile-card">
                                <img className="tech-image" src='./images/technologyImages/headshots/AlagappanS_Crop.png' alt='AL' />
                                <div className="person-text-con">
                                    <h4 className="tech-name">Alagappan Sellappan</h4>
                                    <p className="title">Data Scientist</p>
                                    <div className="linked-in-con">
                                        <FaLinkedin />
                                        <p>View LinkedIn</p>
                                    </div>
                                </div>
                            </div>
                        </NavLink>

                        <NavLink target="_blank" className="no-underline" to={"https://www.linkedin.com/in/dilan-doshi/"}>
                            <div className="profile-card">
                                <img className="tech-image" src='./images/technologyImages/headshots/DilanD_Crop.jpg' alt='Dilan' />
                                <div className="person-text-con">
                                    <h4 className="tech-name">Dilan Doshi</h4>
                                    <p className="title">Software Engineer</p>
                                    <div className="linked-in-con">
                                        <FaLinkedin />
                                        <p>View LinkedIn</p>
                                    </div>
                                </div>
                            </div>
                        </NavLink>

                        <NavLink target="_blank" className="no-underline" to={"https://www.linkedin.com/in/christopher-kusmana-29565630a/"}>
                            <div className="profile-card">
                                <img className="tech-image" src='./images/technologyImages/headshots/placeholder.jpg' alt='Christopher' />
                                <div className="person-text-con">
                                    <h4 className="tech-name">Christopher Kusmana</h4>
                                    <p className="title">Software Engineer Intern</p>
                                    <div className="linked-in-con">
                                        <FaLinkedin />
                                        <p>View LinkedIn</p>
                                    </div>
                                </div>
                            </div>
                        </NavLink>
                    </div>






                </div>
            </div>
        </>
    )
}
