import React from 'react'
import Banner from '../components/Banner'
import '../css/Technology.css'
import { NavLink } from 'react-router-dom'
import { FaLinkedin } from "react-icons/fa";
import { useEffect } from 'react';



export default function Talent() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Banner
                title="Internship & Talent Acquisition Support"
                backgroundImage="/images/Internship.jpeg"
                className="tech-banner"
            />
            <div className='technology-con'>


                <div className='tech-text-con'>
                    <h1 className='tech-h1'>Internship Program Design</h1>

                    <p className='tech-info'>Internship programs and talent acquisition are important for
                        building a strong workforce. Internships help companies find new
                        talent by giving interns hands-on experience with real-world
                        challenges. This creates a group of skilled professionals who know
                        the company and bring fresh ideas. By helping you attract top
                        talent, we will ensure you have the skills needed to grow.</p>

                    <hr class="page-break" />

                    <h2 className='tech-h2'>Our Solutions</h2>

                    <div className="images-con">
                        <div className="profile-card">
                            <img src="/images/Internship_Solutions/Intern_Program_Crop.png" alt="Internship Program Design" />
                            <h3 className="h3-title">Internship Program Design</h3>
                        </div>
                        <div className="profile-card">
                            <img src="/images/Internship_Solutions/Intern_Feedback_Crop.png" alt="Intern Feedback Analysis" />
                            <h3 className="h3-title">Intern Feedback Analysis</h3>
                        </div>
                        <div className="profile-card">
                            <img src="/images/Internship_Solutions/Compensation.jpg" alt="Compensation Packages" />
                            <h3 className="h3-title">Compensation Packages</h3>
                        </div>
                        <div className="profile-card">
                            <img src="/images/Internship_Solutions/Talent_Acquisition_Crop.png" alt="Talent Acquisition" />
                            <h3 className="h3-title">Talent Acquisition</h3>
                        </div>





                    </div>
                    <hr class="page-break" />

                    <h2 className='tech-h2'>Our Team</h2>
                    <div className="tech-grid">
                        <NavLink target="_blank" className="no-underline" to={"https://www.linkedin.com/in/armin-brumand-aa5756297/"}>
                            <div className="profile-card">
                                <img className="tech-image" src="\images\headshots\Armin_Brumand_Headshot.JPG" alt="Armin Brumand" />
                                <div className="person-text-con">
                                    <h4 className="tech-name">Armin Brumand</h4>
                                    <p className="title">Digital Marketing Specialist</p>
                                    <div className="linked-in-con">
                                        <FaLinkedin />
                                        <p>View LinkedIn</p>
                                    </div>
                                </div>
                            </div>
                        </NavLink>

                        <NavLink target="_blank" className="no-underline" to={"https://www.linkedin.com/in/alsellappan/"}>
                            <div className="profile-card">
                                <img className="tech-image" src="\images\headshots\Alagappan_Sellappan_Headshot.jpg" alt="Alagappan Sellapan" />
                                <div className="person-text-con">
                                    <h4 className="tech-name">Alagappan Sellapan</h4>
                                    <p className="title">Data Scientist</p>
                                    <div className="linked-in-con">
                                        <FaLinkedin />
                                        <p>View LinkedIn</p>
                                    </div>
                                </div>
                            </div>
                        </NavLink>

                        <NavLink target="_blank" className="no-underline" to={"https://www.linkedin.com/in/kadenconnelley/"}>
                            <div className="profile-card">
                                <img className="tech-image" src="\images\headshots\Kaden_Connelley_Headshot.jpg" alt="Kaden Connelley" />
                                <div className="person-text-con">
                                    <h4 className="tech-name">Kaden Connelley</h4>
                                    <p className="title">Clinical Intern</p>
                                    <div className="linked-in-con">
                                        <FaLinkedin />
                                        <p>View LinkedIn</p>
                                    </div>
                                </div>
                            </div>
                        </NavLink>

                        <NavLink target="_blank" className="no-underline" to={"https://www.linkedin.com/in/luka-afonine/"}>
                            <div className="profile-card">
                                <img className="tech-image" src="./images/MarketAndBDImages/headshots/LukaA_Crop.png" alt="Luka Afonine" />
                                <div className="person-text-con">
                                    <h4 className="tech-name">Luka Afonine</h4>
                                    <p className="title">Research Assistant</p>
                                    <div className="linked-in-con">
                                        <FaLinkedin />
                                        <p>View LinkedIn</p>
                                    </div>
                                </div>
                            </div>
                        </NavLink>

                        <NavLink target="_blank" className="no-underline" to={"https://www.linkedin.com/in/ananya-pradhan-008115246/"}>
                            <div className="profile-card">
                                <img className="tech-image" src="\images\headshots\Ananya_Pradhan_Headshot.jpg" alt="Ananya Pradhan" />
                                <div className="person-text-con">
                                    <h4 className="tech-name">Ananya Pradhan</h4>
                                    <p className="title">Marketing Intern</p>
                                    <div className="linked-in-con">
                                        <FaLinkedin />
                                        <p>View LinkedIn</p>
                                    </div>
                                </div>
                            </div>
                        </NavLink>
                    </div>
                </div>
            </div>
        </>
    )
}
