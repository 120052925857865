import React from "react";
import "../css/Contact.css";
import Banner from '../components/Banner';

function Contact() {
  return (
    <>
      {/* Contact Banner
      <div className="contact-banner">
        <h1 className="contact-main-title">Contact Us</h1>
        <p className="contact-subtitle">Let's keep in touch</p>
      </div> */}
      <div>
      <Banner 
        title="Contact Us" 
        subtitle="Let's keep in touch"
        backgroundVideo="/images/Contact_Motion.mp4"
        backgroundImage="/images/contact-banner-placeholder.jpg"
      />
      </div>
      <h2 className="contact-title">Feel free to reach out to our team.</h2>
      <div className="contact-container">

        <div className="contact-form">
          <form method="POST" action="https://formspree.io/f/mpwzrjbq">
          <div className="form-group">
            <label className="form-label">Full Name</label>
            <input
              type="text"
              placeholder="Enter your full name"
              className="form-input"
              name="Full Name"
            />
          </div>

          <div className="form-group">
            <label className="form-label">Email</label>
            <input
              type="email"
              placeholder="Enter your email"
              className="form-input"
              name="Email Address"
            />
          </div>

          <div className="form-group">
            <label className="form-label">Message</label>
            <textarea
              rows={4}
              placeholder="Enter your message"
              className="form-textarea"
              name="Message"
            />
          </div>
          <button type="submit" className="submit-button">Submit</button>
          </form>
        </div>
      </div>
    </>
  );
}

export default Contact;
