import React from "react";
// import Header from "../components/Header";
// import Footer from "../components/Footer";
import "../css/Contact.css";
import Banner from '../components/Banner';

function Newsletter() {
  return (
    <>
    {/* <div className="contact-banner">
        <h1 className="contact-main-title">Sign-up for the Newsletter!</h1>
        <p className="contact-subtitle">Let's keep in touch</p>
    </div> */}
    <div>
      <Banner 
        title="Sign Up For The Newsletter!" 
        subtitle="Let's keep in touch"
        backgroundVideo="/images/beach.mp4"
      />
      </div>
      <div id="mc_embed_shell">
        <link
          href="//cdn-images.mailchimp.com/embedcode/classic-061523.css"
          rel="stylesheet"
          type="text/css"
        />
        <style type="text/css">
          {`
            #mc_embed_signup {
              background: #fff;
              clear: left;
              font: 14px Helvetica, Arial, sans-serif;
              width: 600px;
            }
            /* Add your own Mailchimp form style overrides in your site stylesheet or in this style block. 
               We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */
          `}
        </style>

        <div id="mc_embed_signup">
          <form
            action="https://gmail.us11.list-manage.com/subscribe/post?u=9488fda34d4b41de789695b45&amp;id=9832b6faa3&amp;f_id=00fe17e1f0"
            method="post"
            id="mc-embedded-subscribe-form"
            name="mc-embedded-subscribe-form"
            className="validate"
            target="_blank"
          >
            <div id="mc_embed_signup_scroll" className="mailchimp-con">
              <h2 className="mail-h2">Subscribe to the BioStrive Newsletter!</h2>
              <div className="indicates-required">
                <span className="asterisk">*</span> indicates required
              </div>
              <div className="mc-field-group">
                <label htmlFor="mce-EMAIL">
                  Email Address <span className="asterisk">*</span>
                </label>
                <input
                  type="email"
                  name="EMAIL"
                  className="required email"
                  id="mce-EMAIL"
                  required
                />
              </div>
              <div className="mc-field-group">
                <label htmlFor="mce-FNAME">
                  First Name <span className="asterisk">*</span>
                </label>
                <input
                  type="text"
                  name="FNAME"
                  className="required text"
                  id="mce-FNAME"
                  required
                />
              </div>
              <div className="mc-field-group">
                <label htmlFor="mce-LNAME">
                  Last Name <span className="asterisk">*</span>
                </label>
                <input
                  type="text"
                  name="LNAME"
                  className="required text"
                  id="mce-LNAME"
                  required
                />
              </div>
              <div className="mc-field-group">
                <label htmlFor="mce-PHONE">Phone Number</label>
                <input
                  type="text"
                  name="PHONE"
                  className="REQ_CSS"
                  id="mce-PHONE"
                />
              </div>
              <div id="mce-responses" className="clear foot">
                <div
                  className="response"
                  id="mce-error-response"
                  style={{ display: "none" }}
                ></div>
                <div
                  className="response"
                  id="mce-success-response"
                  style={{ display: "none" }}
                ></div>
              </div>
              <div aria-hidden="true" style={{ position: "absolute", left: "-5000px" }}>
                {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups */}
                <input
                  type="text"
                  name="b_9488fda34d4b41de789695b45_9832b6faa3"
                  tabIndex="-1"
                />
              </div>
              <div className="optionalParent">
                <div className="clear foot">
                  <input
                    type="submit"
                    name="subscribe"
                    id="mc-embedded-subscribe"
                    className="button"
                    value="Subscribe"
                  />
                  <p style={{ margin: "0px auto" }}>
                    <a
                      href="http://eepurl.com/i2L02E"
                      title="Mailchimp - email marketing made easy and fun"
                    >
                      <span
                        style={{
                          display: "inline-block",
                          backgroundColor: "transparent",
                          borderRadius: "4px",
                        }}
                      >
                        <img
                          className="refferal_badge"
                          src="https://digitalasset.intuit.com/render/content/dam/intuit/mc-fe/en_us/images/intuit-mc-rewards-text-dark.svg"
                          alt="Intuit Mailchimp"
                          style={{
                            width: "220px",
                            height: "40px",
                            display: "flex",
                            padding: "2px 0px",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        />
                      </span>
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default Newsletter;
