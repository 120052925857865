import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import Contact from "./pages/Contact";
import Services from "./pages/Services";
import Newsletter from "./pages/Newsletter";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Technology from "./pages/Technology";
import MarketAndBD from "./pages/MarketAndBD";
import "./App.css";
import MarketAccess from "./pages/MarketAccess";
import Talent from "./pages/Talent";

function App() {
  return (
    <Router>
      <Header />

      <div className="main-content">
        {" "}
        {/* Apply padding for the fixed header */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/newsletter" element={<Newsletter />} />
          <Route path="/technology" element={<Technology />} />
          <Route path="/market-access" element={<MarketAccess />} />
          <Route path="/MarketAndBD" element={<MarketAndBD />} />
          <Route path="/Talent" element={<Talent />} />
        </Routes>
      </div>

      <Footer />
    </Router>
  );
}

export default App;
