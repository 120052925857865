import React from "react";
import Banner from "../components/Banner";
import "../css/Services.css";
import { NavLink } from "react-router-dom";

function Services() {
  return (
    <div>
      <Banner
        title="Services"
        backgroundImage="/images/contact-banner-placeholder.jpg"
      />

      <div className="services-content">
        <div className="services-grid">
          <div className="service-item">
            <img
              src="/images/Market_access.jpeg"
              alt="Market Access & Evidence Generation"
            />
            <h3>Market Access & Evidence Generation</h3>
          </div>
          <NavLink to={"/technology"}>
            <div className="service-item">
              <img src="/images/Technology.jpeg" alt="Technology" />
              <h3>Technology</h3>
            </div>
          </NavLink>
          <NavLink to={"/MarketAndBD"}>
            <div className="service-item">
              <img
                src="/images/MarketingBusiness.jpeg"
                alt="Marketing & Business Development"
              />
              <h3>Marketing & Business Development</h3>
            </div>
          </NavLink>
          <NavLink to={"/Talent"}>
            <div className="service-item">
              <img
                src="/images/internship.jpeg"
                alt="Internship & Talent Acquisition"
              />
              <h3>Internship & Talent Acquisition</h3>
            </div>
          </NavLink>
        </div>
      </div>
    </div>
  );
}

export default Services;
