import React from "react";
import "./Banner.css";

function Banner({ title = "", subtitle, backgroundVideo, backgroundImage, logo }) {
  const [firstPart, secondPart] =
    title && title.includes("|") ? title.split("|") : [title, ""];

  return (
    <div className="banner">
      {backgroundVideo ? (
        <video autoPlay loop muted playsInline className="background-video">
          <source src={backgroundVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      ) : backgroundImage ? (
        <div
          className="background-image"
          style={{ backgroundImage: `url(${backgroundImage})` }}
        ></div>
      ) : null}

      {logo ? (
        <img src={logo} alt="Logo" className="banner-logo" />
      ) : (
        <h1 className="banner-title">
          {firstPart}
          {secondPart && <br />}
          {secondPart}
        </h1>
      )}
      <p className="banner-subtitle">{subtitle}</p>
    </div>
  );
}

export default Banner;