import React, { useState, useEffect } from 'react'
import Banner from '../components/Banner'
import '../css/MarketAccess.css'

export default function MarketAccess() {
    const [activeModal, setActiveModal] = useState(null);
    const [showMoreExperience, setShowMoreExperience] = useState(false);
    const handleShowMoreExperience = () => {
        setShowMoreExperience((prevShowMore) => !prevShowMore);
    };

    // thank you zachary my goat for this
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    // Partner data array
    const partners = [
        {
            id: 1,
            name: 'Kurt Burgess',
            title: 'Founder, BD Catalysts',
            bio: 'Kurt has dedicated his career to being a successful business advisor to executives, working directly with entrepreneurs and economic development specialists to develop key strategic relationships in an effort to uncover commercial opportunities across the globe for his clients. He has worked with a diverse range of clientele, including industry leaders such as Bioventus, Depuy Synthes, Dfine (acquired by Merit Medical for $97.5 MM), Medacta, Moximed, OrthoPediatrics, Salient Surgical (acquired by Medtronic for $525 MM), Smith & Nephew, United Orthopedic Corp, Vertiflex (acquired by Boston Scientific for over $450 MM), and Zimmer Biomet. Kurt’s career includes key senior management and founder positions at companies such as BD Catalysts, LLC; Clinical Alliance Group (merger); Burgess Ventures, LLC; Telos Partners, LLC (acquired); CAS Systems (acquired); Rainmaker Systems; and Hilton Hotels.',
            image: '/images/marketHeadshots/Kurt_Crop.png',
        },
        {
            id: 2,
            name: 'Dr. Jeff Muir',
            title: 'Advisor',
            bio: 'Dr. Jeff Muir is a seasoned clinical researcher with 25 years of experience and broad expertise that spans orthopedics, public health, oncology, and autoimmune diseases. Dr. Muir specializes in real-world evidence, with a strong emphasis on evidence generation through needs assessment, study design, execution, and knowledge translation. His work plays a crucial role in supporting regulatory approvals, post-market research, and marketing applications. Throughout his career, Dr. Muir has collaborated with a wide range of clients, including leading medical device companies such as Intellijoint Surgical, Inc.; OA Centers of America; Moximed, Inc.; and United Orthopedics. His pharmaceutical clients include industry leaders such as Merck KGaA, Boehringer Ingelheim, Takeda Pharmaceuticals America, and Roche. Additionally, Dr. Muir has worked with clients in the academic and governmental sectors, including McMaster University and the Public Health Agency of Canada. Dr. Muir is also deeply involved in public health initiatives, particularly those related to health equity and artificial intelligence. ',
            image: '/images/marketHeadshots/Jeff_Crop.png',
        },
        {
            id: 3,
            name: 'Dawn Fallon',
            title: 'Advisor',
            bio: "Dawn Fallon is a leader in corporate and product communications with over 20 years of experience. She has represented both well-established and dynamic emerging healthcare companies, leading strategic awareness initiatives. Dawn has executed communications programs for approximately 100 start-up, preclinical, and clinical-stage companies, covering nearly every healthcare market category. Dawn’s experience spans clients in the medical device and technology sectors, including Abiomed, Canary Medical, CytoReason, DermaSensor, GT Medical Technologies, Humacyte, Insulet Corporation, and several divisions of Johnson & Johnson such as Codman Neuro, DePuy Mitek, DePuy Orthopedics, DePuy Spine, and Ethicon Endo-Surgery. She has also worked with Medtronic's ENT, Minimally Invasive Therapy Group, Neurovascular, and Respiratory divisions, as well as other notable companies like Nuvo Group, Osteogene, PowerVision, Sernova, Theragen, ThoraGenix, TMRW, United Orthopedic Corporation, and Vilex. Dawn’s areas of focus include corporate communications, digital marketing, key opinion leader mapping, influencer marketing, and product communications.",
            image: '/images/marketHeadshots/Dawn_Crop.jpg',
        },
        {
            id: 4,
            name: 'Mindy Vander Leest',
            title: 'Advisor',
            bio: "Mindy Vander Leest is highly experienced in clinical research and trials with 25 years of expertise in the field. Her background encompasses clinical strategy and study design, clinical evidence generation, and the development of protocols and study tools. Mindy has a deep understanding of global trial execution and compliance, with a strong command of regulatory frameworks. Her proficiency extends to managing regulatory inspections and audits, as well as overseeing team management, training, and mentoring. She is also skilled in SOP development, quality audits, HCP compliance, and managing limited commercial releases and MDR execution. Throughout her career, Mindy has worked with clients across multiple sectors. In the CRO sector, she has partnered with organizations such as Converge Clinical, KLEO, Emericlin, Emeritus, and Novotech. Her work with medical device companies includes collaborations with United Orthopedics, Zyga Technology, AGA Medical, and St. Jude Medical, among others. In the pharmaceutical and BioTech industries, she has worked with PureTech Health and Veru Inc. Additionally, Mindy has served as an expert witness for Halunen Law.",
            image: '/images/marketHeadshots/Mindy_Crop2.png',
        },
        {
            id: 5,
            name: 'Dr. Robert Swain',
            title: 'Advisor',
            bio: "Dr. Rob Swain, PhD, is an expert in engineering mechanics with over 30 years of experience in the medical field, offering a broad range of expertise in regulatory affairs, medical writing, statistical analysis, strategic planning, pre-clinical and clinical research, human factors engineering, and legal affairs. His extensive background spans working with companies of all sizes, from start-ups to Fortune 500 firms. Dr. Swain’s ability to navigate the complexities of many fields and develop strategic, regulatory, and research-oriented solutions has made him highly sought-after in the medical industry.",
            image: '/images/marketHeadshots/Robert_Crop.png',
        },
        {
            id: 6,
            name: 'Jim Viola, MBA',
            title: 'Advisor',
            bio: "Jim Viola, MBA, is an executive with nearly three decades of experience in marketing and brand development, specializing in the healthcare sector. In the healthcare space, his work with Johnson & Johnson was instrumental in launching the JLABS brand in the North American market. Jim supported key partnerships for JLABS, assisted innovators along the product development continuum, and established strong relationships with the broader BioTech community. Jim also has experience in branding outside of healthcare, bringing a unique perspective to the industry. He has made significant contributions to the branding and marketing strategies of BMW of North America and Ducati North America. Jim serves on the advisory board of CELS, a non-profit organization dedicated to supporting Canadian life science companies. Beyond his professional achievements, Jim is also actively involved in various event committees in the San Francisco Bay Area. Notably, Jim and his son hold a Guinness World Record for the longest flag football game, highlighting his passion both professionally and personally.",
            image: '/images/marketHeadshots/Jim_Crop.png',
        },
    ];

    const experiences = [
        { logo: '/images/marketLogos/novartis.png', name: 'Novartis' },
        { logo: '/images/marketLogos/takeda.png', name: 'Takeda' },
        { logo: '/images/marketLogos/medtronic.png', name: 'Medtronic' },
        { logo: '/images/marketLogos/zimmer.png', name: 'Zimmer Biomet' },
        { logo: '/images/marketLogos/JJ.png', name: 'Johnson & Johnson' },
        { logo: '/images/marketLogos/smithnephew.png', name: 'SmithNephew' },
        { logo: '/images/marketLogos/merck.png', name: 'Merck' },
        { logo: '/images/marketLogos/boehringer.png', name: 'Boehringer Ingelheim' },
        { logo: '/images/marketLogos/roche.png', name: 'Roche' },
        { logo: '/images/marketLogos/biogen.png', name: 'Biogen' },
        { logo: '/images/marketLogos/gsk.png', name: 'GSK' }
    ]


    const openModal = (id) => {
        setActiveModal(id);
    };
    const closeModal = () => {
        setActiveModal(null);
    };
    return (<>
    <Banner
            title="Market Access & Evidence Generation"
            backgroundImage="/images/Market_Access.jpeg"
            className="market-banner"
        />
    <div className='Market-Container'>
        
        {/* Reusing homepage containers because the styling's visually identical. 2 birds with one stone */}
        <div className='homepage-container'>
            <div className='homepage-paragraph-container'>
                <h1 className='homepage-header'>Health Economics, Market Access, and Clinical Publication Roadmapping</h1>
                <p className='market-acc-p'>In the world of healthcare commercialization, the path to success is paved with published evidence. At BioStrive, we will transform evidence into a compelling narrative that ensures market success. Our partnership with BD Catalysts provides us with industry knowledge to make us the ideal partner for any life science company looking to enhance their publication portfolio.</p>
            </div>
            <div class="solutions-section">
                <h1 className="solutions-h1">Our Solutions</h1>
                <div class="solutions-grid">
                    <div class="solution-card">
                        <img src="/images/marketImages/Publication_Roadmaps.jpg" alt="Publication Roadmaps" />
                        <p className='solutions-card-p'>Publication Roadmaps & Development</p>
                    </div>
                    <div class="solution-card">
                        <img src="/images/marketImages/Key_Opinion.png" alt="Key Opinion Leader Advocacy" />
                        <p className='solutions-card-p'>Key Opinion Leader Advocacy</p>
                    </div>
                    <div class="solution-card">
                        <img src="/images/marketImages/Health_Economics.jpg" alt="Health Economics & Outcomes Research" />
                        <p className='solutions-card-p'>Health Economics & Outcomes Research</p>
                    </div>
                    <div class="solution-card">
                        <img src="/images/marketImages/Regulatory.jpg" alt="Regulatory & Clinical Affairs" />
                        <p className='solutions-card-p'>Regulatory & Clinical Affairs</p>
                    </div>
                </div>
            </div>
            <div className="partners-section">
                <h1 className="homepage-header partners-title">Our Partners</h1>
                <div className="partners-grid">
                    {partners.map((partner) => (
                        <div className="profile-card" key={partner.id}>
                            <img src={partner.image} alt={partner.name} />
                            <h2>{partner.name}</h2>
                            <p>{partner.title}</p>
                            <button className="bio-button" onClick={() => openModal(partner.id)}>
                                Biography &rsaquo;
                            </button>
                        </div>
                    ))}
                </div>

                {/* modals mapper */}
                {partners.map((partner) => (
                    <div
                        className={`modal ${activeModal === partner.id ? 'show' : ''}`}
                        key={`modal-${partner.id}`}
                    >
                        <div className="modal-content">
                            <span className="close" onClick={closeModal}>
                                &times;
                            </span>

                           
                            <div className="modal-top">
                                <img src={partner.image} alt={partner.name} />
                                <div className="modal-top-text">
                                    <h2>{partner.name}</h2>
                                    <p>{partner.title}</p>
                                </div>
                            </div>

                            
                            <div className="modal-bottom">
                                <h3>Biography</h3>
                                <p>{partner.bio}</p>
                            </div>
                        </div>
                    </div>
                ))}


            </div>
            <section className="experience">
                <div className = "experience-heading">
                    <h2 className='teams-h2'>Our Team's Experience</h2>
                </div>
                <div className={`experience-grid ${showMoreExperience ? 'expanded' : ''}`}>
                    {experiences.slice(0, showMoreExperience ? experiences.length : 6).map((exp, index) => (
                        <div key={index} className="experience-card">
                            <img src={exp.logo} alt={exp.name} className="experience-logo" />
                        </div>
                    ))}
                </div>
                <button className="show-more" onClick={handleShowMoreExperience}>
                    {showMoreExperience ? 'Hide' : 'Show more'}
                    <span className={`arrow ${showMoreExperience ? 'up' : ''}`}>&#8250;</span>
                </button>
            </section>

        </div>

    </div>
    </>)
}